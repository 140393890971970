import "../client-onboarding/onBoarding.css";

const PnlTable = ({ pnlRecords, handleDeleteModal, setOpenModal, type }) => {
  return (
    <div className="overflow-auto w-full overflow-md-none">
      <table className="table table-striped table-bordered table-hover mt-3 small">
        <thead className="text-center">
          <tr>
            <th></th>
            <th></th>
            <th scope="col" colSpan="4">
              As on Date
            </th>
            <th scope="col" colSpan="5">
              Capital Gains Booked
            </th>
            <th></th>
          </tr>
          <tr>
            <th scope="col" className="cstm-big-width">
              Client Name
            </th>
            <th scope="col">Date</th>
            <th scope="col">Equity</th>
            <th scope="col">MF</th>
            <th scope="col">Currency</th>
            <th scope="col">FNO</th>
            <th scope="col">Equity</th>
            <th scope="col">Currency</th>
            <th scope="col">MF</th>
            <th scope="col">FNO</th>
            <th scope="col">Net Booked</th>
            <th scope="col">Sum</th>
            {type === "admin" && <th scope="col">Action</th>}
          </tr>
        </thead>
        <tbody className="text-center">
          {pnlRecords.length > 0 ? (
            pnlRecords.map((record) => (
              <tr key={record.id}>
                <td className="text-capitalize">{record.client_name}</td>
                <td>{record.date}</td>
                <td>{record.as_on_equity}</td>
                <td>{record.as_on_mf}</td>
                <td>{record.as_on_currency}</td>
                <td>{record.as_on_fno}</td>
                <td>{record.equity}</td>
                <td>{record.currency}</td>
                <td>{record.mf}</td>
                <td>{record.fno}</td>
                <td>{record.net_booked}</td>
                <td>{record.sum}</td>
                {type === "admin" && (
                  <td>
                    <div className="d-flex align-items-center">
                      <i
                        className="bi bi-pencil-square text-primary action-icon mx-1 fs-6"
                        onClick={() =>
                          setOpenModal({ open: true, data: record })
                        }
                      ></i>
                      <i
                        className="bi bi-trash-fill text-danger action-icon mx-1 fs-6"
                        onClick={() => handleDeleteModal(record.id)}
                      ></i>
                    </div>
                  </td>
                )}
              </tr>
            ))
          ) : (
            <tr className="text-center">
              <td colSpan="12">No Record Found</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default PnlTable;
