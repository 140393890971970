import "./App.css";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import Signup from "./components/pages/signup-page/signup";
import Login from "./components/pages/login-page/login";
import RiskProfile from "./components/pages/risk-profile/riskProfile";
import ResetPassword from "./components/pages/login-page/resetPassword";
import SuitabilityPage from "./components/pages/suitability-assessment-page/suitabilityPage";
import Ekyc from "./components/pages/ekyc-page/ekyc";
import Success from "./components/pages/ekyc-page/Success";
import RiskResult from "./components/pages/risk-profile/riskResult";
import AdminLogin from "./components/pages/login-page/adminLogin";
import ClientOnBoarding from "./components/pages/client-onboarding/clientOnBoarding";
import DocumentVault from "./components/pages/document-vault/documentVault";
import ClientDetails from "./components/pages/client-onboarding/clientDetails";
import AdminDashboard from "./components/pages/admin-dashboard/adminDashboard";
import UserDashboard from "./components/pages/user-dashboard/userDashboard";
import ViewRiskProfiles from "./components/pages/document-vault/ViewRiskProfiles";
import Esign from "./components/pages/esign/Esign";
import ViewEsignDocs from "./components/pages/document-vault/ViewEsignDocs";
import Expenses from "./components/pages/expenses/expenses";
import TermsAndCondition from "./components/pages/TermsAndCondition";
// import FeePayment from "./components/pages/ekyc-page/feePayment";
import RegisterUser from "./components/pages/register-user-admin/registerUser";
import PnlRecords from "./components/pages/pnl/pnlRecords";
import UserRecords from "./components/pages/pnl/userRecords";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/admin-dashboard" element={<AdminDashboard />} />
        <Route path="/user-dashboard" element={<UserDashboard />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/admin-login" element={<AdminLogin />} />
        <Route path="/risk-profile" element={<RiskProfile />} />
        <Route path="/risk-result" element={<RiskResult />} />
        <Route path="/reset-password/:token" element={<ResetPassword />} />
        <Route path="/suitability-assessment" element={<SuitabilityPage />} />
        <Route path="/e-kyc" element={<Ekyc />} />
        <Route path="/success" element={<Success />} />
        {/* <Route path="/fee-payment" element={<FeePayment />} /> */}
        <Route path="/e-sign" element={<Esign />} />
        <Route path="/client-onboarding" element={<ClientOnBoarding />} />
        <Route path="/document-vault" element={<DocumentVault />} />
        <Route path="/client-details" element={<ClientDetails />} />
        <Route path="/view-risk-profiles" element={<ViewRiskProfiles />} />
        <Route path="/view-esign-docs" element={<ViewEsignDocs />} />
        <Route path="/expenses" element={<Expenses />} />
        <Route path="/terms-and-condition" element={<TermsAndCondition />} />
        <Route path="/pnl-records" element={<PnlRecords />} />
        <Route path="/register-user" element={<RegisterUser />} />
        <Route path="/records" element={<UserRecords />} />
      </Routes>
    </Router>
  );
}

export default App;
