import React, { useEffect, useState } from "react";
import { deleteUser } from "../../../store/slices/UserSlice";
import { useNavigate } from "react-router-dom";
import { UserSidebar } from "../../layout/userSidebar";
import { useDispatch, useSelector } from "react-redux";
import PnlTable from "./pnlTable";
import { Loader } from "../../ui/loader/Loader";
import UserNavbar from "../../layout/userNavbar";
import ResponsiveSidebar from "../../layout/responsiveSidebar";

const UserRecords = () => {
  const pnlDataApi = `${process.env.REACT_APP_URL}display-user-pnl-record`;

  const navigate = useNavigate();
  const fnDispatch = useDispatch();

  const token = useSelector((state) => {
    return state.users.data?.token;
  });

  const [isLoading, setIsLoading] = useState(false);
  const [pnlRecords, setPnlRecords] = useState([]);
  const [navbar, setNavbar] = useState(false);

  useEffect(() => {
    const fetchData = setTimeout(async () => {
      setIsLoading(true);
      try {
        const res = await fetch(pnlDataApi, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: {
            token: token,
          },
        });
        var data = await res.json();
        if (data.status === 200) {
          setPnlRecords(data.data);
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        alert("Sorry, something went wrong " + error.message);
      }
    });
    return () => {
      clearTimeout(fetchData);
    };
  }, [token, pnlDataApi]);

  const Logout = () => {
    fnDispatch(deleteUser());
    navigate("/");
  };

  return (
    <section
      className="user-dashboard position-relative"
      style={{ height: "100vh" }}
    >
      <ResponsiveSidebar navbar={navbar} setNavbar={setNavbar} />
      <UserNavbar setNavbar={setNavbar} />
      <div className="row m-0 h-lg-100">
        <div className="position-relative col-12 col-lg-2 p-0 bg-lg-primary">
          <UserSidebar />
          <button
            className="position-absolute bottom-0 w-100 border-0 text-center p-2 d-none d-lg-block"
            style={{
              backgroundColor: "#f4f3f3",
              fontSize: "22px",
              cursor: "pointer",
            }}
            onClick={Logout}
          >
            <i className="bi bi-box-arrow-right me-1 text-danger"></i>
            <span>LogOut</span>
          </button>
        </div>
        <div className="col-12 col-lg-10 p-3">
          <h5 className="mb-3">PNL Records</h5>
          {isLoading ? (
            <div className="d-flex justify-content-center mt-5">
              <Loader />
            </div>
          ) : (
            <PnlTable pnlRecords={pnlRecords} type="user" />
          )}
        </div>
      </div>
    </section>
  );
};

export default UserRecords;
